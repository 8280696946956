<template>
  <v-app>
    <v-navigation-drawer
      width="76"
      class="pt-12"
      app
      permanent
      stateless
      style="z-index: 10"
    >
      <v-btn-toggle
        v-model="activedTool"
        tile
        borderless
        class="d-flex flex-column justify-start align-center py-1 full-height"
      >
        <v-btn
          v-for="(btn, idx) in navConfig"
          :key="idx"
          v-show="btn.visible"
          width="72"
          height="72"
          class="pa-0 my-0 overflow-hidden"
          tile
          plain
          :disabled="btn.disabled || !modelExist"
        >
          <v-card
            width="60"
            height="60"
            class="d-flex flex-column justify-center align-center transparent"
            tile
            flat
          >
            <v-img
              v-if="darkMode"
              width="50%"
              aspect-ratio="1"
              :src="btn.svg.replace('.svg', '-white.svg')"
              contain
            ></v-img>
            <v-img
              v-else
              width="50%"
              aspect-ratio="1"
              :src="btn.svg"
              contain
            ></v-img>
            <span class="caption text-center">{{ btn.title }}</span>
          </v-card>
        </v-btn>
      </v-btn-toggle>
    </v-navigation-drawer>

    <v-app-bar app clipped-left flat dense outlined style="z-index: 11">
      <v-app-bar-title class="mr-2">
        <v-img
          height="40"
          :width="(6234 / 1800) * 40"
          src="@/assets/syncobox_logo.png"
          dense
          dark
        ></v-img>
      </v-app-bar-title>

      <breadcrumbs
        :current-project="currentProject"
        :current-zone="currentZone"
        :current-bimset="currentBimSet"
        :zone-list="zoneList"
        :bimset-list="bimSetList"
        :disable-zone-list="disableZoneList"
        :disable-bimset-list="disableBimSetList"
        @updated:zone="zoneOnChange"
        @updated:bimset="bimsetOnChange"
      />

      <v-spacer></v-spacer>

      <v-menu transition="slide-y-transition" offset-y bottom>
        <template #activator="{ on: menu, attrs }">
          <v-tooltip bottom>
            <template #activator="{ on: tooltip }">
              <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                <v-icon>mdi-account-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $tc("account") }}</span>
          </v-tooltip>
        </template>
        <v-list dense>
          <v-list-item>
            <v-list-item-icon class="my-auto">
              <v-icon>mdi-account-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ user.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="my-2" />
          <v-list-item @click="logout">
            <v-list-item-icon class="my-auto">
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $tc("logout") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <Setting ref="setting" />
    </v-app-bar>

    <v-main>
      <forge-layout
        v-if="currentBimSet && !refresher"
        :bimset="currentBimSet"
        :activatedTool="currentTool"
        @ready="forgeOnReady"
        @tool:deactivated="toolDeactivated"
      ></forge-layout>

      <v-overlay
        absolute
        :opacity="1"
        :value="!viewerReady && modelExist"
        :color="darkMode ? '#424242' : '#BDBDBD'"
        class="d-flex flex-column justify-center align-center"
        style="padding-left: 76px"
      >
        <div v-if="!viewerReady" class="subtitle-1 mb-2 text-center">
          Loading ...
        </div>
        <v-progress-linear
          :color="darkMode ? 'primary' : 'primary lighten-1'"
          indeterminate
          rounded
          height="16"
          style="width: 24vw"
        ></v-progress-linear>
      </v-overlay>
    </v-main>
  </v-app>
</template>

<script>
import Vue from "vue";
import navConfig from "@/configs/nav.json";
import Setting from "@/components/Setting.vue";
import ForgeLayout from "@/layouts/Forge.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import { mapActions } from "vuex";

export default {
  name: "WeBIMSyncLayout",
  components: { Setting, ForgeLayout, Breadcrumbs },
  props: {
    defalutProjectId: {
      type: String,
      default: null,
    },
    defalutZoneId: {
      type: String,
      default: null,
    },
    defalutBimSetId: {
      type: String,
      default: "publish",
    },
  },
  data: () => ({
    drawer: null,
    navConfig: navConfig,
    currentProject: null,
    currentZone: null,
    currentBimSet: null,
    currentTool: null,
    activedTool: null,
    zoneList: [],
    disableZoneList: false,
    disableBimSetList: false,
    bimSetList: [],
    refresher: false,
    viewerReady: false,
  }),
  computed: {
    user() {
      return this.$store.state.auth.userInfo;
    },
    modelExist() {
      return (
        this.currentBimSet &&
        this.currentBimSet.bimVersions.length > 0 &&
        this.currentBimSet.bimVersions.filter((e) => e.derivativeId).length > 0
      );
    },
    darkMode() {
      return this.$vuetify.theme.isDark;
    },
  },
  created() {
    this.getProjectInfo(this.defalutProjectId);
    this.getZoneList(this.defalutProjectId);

    this.getZoneInfo(this.defalutProjectId, this.defalutZoneId);
    this.getBimSetList(this.defalutProjectId, this.defalutZoneId);

    this.getBimSet(
      this.defalutProjectId,
      this.defalutZoneId,
      this.defalutBimSetId
    ).then(() => {
      this.viewerReady = true;
    });
  },
  mounted() {},
  methods: {
    ...mapActions("oidcStore", [
      "signOutOidc", // Signs out user in open id provider
    ]),
    getProjectInfo(projectId) {
      return Vue.prototype.$API.api.main.project.get(projectId).then((res) => {
        this.currentProject = res.data;
      });
    },
    getZoneInfo(projectId, zoneId) {
      return Vue.prototype.$API.api.main.projectsZones
        .get(projectId, zoneId)
        .then((res) => {
          this.currentZone = res.data;
        });
    },
    getBimSet(projectId, zoneId, bimsetId) {
      if (bimsetId === "publish") {
        Vue.prototype.$API.api.bim.projectsZonesBIMSets._setVersion("v1.4");
        return Vue.prototype.$API.api.bim.projectsZonesBIMSets
          .getPublishBIMSet(projectId, zoneId)
          .then((res) => {
            this.currentBimSet = res.data;
            if (this.bimSetList.findIndex((e) => e.id === res.data.id) < 0) {
              this.bimSetList.unshift({
                ...res.data,
                name: `Ver. ${res.data.version} (Preview)`,
              });
            }
            this.refresher = false;
          });
      } else {
        Vue.prototype.$API.api.bim.projectsZonesBIMSets._setVersion("v1.4");
        return Vue.prototype.$API.api.bim.projectsZonesBIMSets
          .getBIMSetById(projectId, zoneId, bimsetId)
          .then((res) => {
            this.currentBimSet = res.data;
            if (this.bimSetList.findIndex((e) => e.id === res.data.id) < 0) {
              this.bimSetList.unshift({
                ...res.data,
                name: `Ver. ${res.data.version} (Preview)`,
              });
            }
            this.refresher = false;
          });
      }
    },
    getZoneList(projectId) {
      return Vue.prototype.$API.api.main.projectsZones
        .getAll(projectId)
        .then((res) => {
          this.zoneList = res.data;
        });
    },
    getBimSetList(projectId, zoneId) {
      Vue.prototype.$API.api.bim.projectsZonesBIMSets._setVersion("v1.4");
      return Vue.prototype.$API.api.bim.projectsZonesBIMSets
        .getBIMSets(projectId, zoneId)
        .then((res) => {
          this.bimSetList = res.data
            .map((e) => ({
              ...e,
              name: `Ver. ${e.version}  ${
                e.isZoneActiveBIMSet ? "(Latest)" : ""
              }`,
            }))
            .filter((e) => e.initUsed);
        })
        .catch(() => {
          this.bimSetList = [];
        });
    },
    zoneOnChange(e) {
      this.viewerReady = false;
      this.disableBimSetList = true;
      this.$router.push(
        {
          name: "WeBIMSync",
          params: {
            projectId: this.currentProject.id,
            zoneId: e.id,
            bimsetId: "publish",
          },
        },
        () => {
          this.getBimSetList(this.defalutProjectId, e.id)
            .then(() => {
              this.disableBimSetList = false;
            })
            .catch(() => {
              this.disableBimSetList = false;
            });
        }
      );
      // document.location.href = `${document.location.origin}/project/${this.currentProject.id}/zone/${e.id}/bimset/publish`;
    },
    bimsetOnChange(e) {
      this.viewerReady = false;
      this.disableZoneList = true;
      this.$router.push(
        {
          name: "WeBIMSync",
          params: {
            projectId: this.currentProject.id,
            zoneId: this.currentZone.id,
            bimsetId: e.id,
          },
        },
        () => {
          this.disableZoneList = false;
        }
      );
      // document.location.href = `${document.location.origin}/project/${this.currentProject.id}/zone/${this.currentZone.id}/bimset/${e.id}`;
    },
    forgeOnReady() {
      this.$refs.setting.initialize();
      this.viewerReady = true;
    },
    toolDeactivated() {
      this.activedTool = undefined;
    },
    logout() {
      this.signOutOidc();
    },
  },
  watch: {
    refresher: {
      immediate: false,
      deep: false,
      handler(refresh) {
        if (!refresh) return;
        this.getBimSet(
          this.defalutProjectId,
          this.defalutZoneId,
          this.defalutBimSetId
        );
      },
    },
    defalutProjectId: {
      immediate: false,
      deep: false,
      handler() {
        this.refresher = true;
      },
    },
    defalutZoneId: {
      immediate: false,
      deep: false,
      handler() {
        this.refresher = true;
      },
    },
    defalutBimSetId: {
      immediate: false,
      deep: false,
      handler() {
        this.refresher = true;
      },
    },
    activedTool: {
      immediate: true,
      deep: false,
      handler(idx) {
        this.currentTool = typeof idx === "number" ? navConfig[idx] : null;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.full-height {
  // For Edge
  height: 100%;
  // WebKit-based browsers will ignore this
  height: -moz-available;
  // Mozilla-based browsers will ignore this.
  height: -webkit-fill-available;
  // For WebKit-based browsers, Edge not support this
  height: stretch;
}
</style>
