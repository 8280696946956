<template>
  <v-card width="100%" height="100%" tile flat :loading="pageLoading">
    <Header @search="onSearch" @close="toggleCloseEvent" />
    <v-card width="100%" height="calc(100% - 108px)" tile flat>
      <perfect-scrollbar :options="{ suppressScrollX: true }">
        <v-list two-line class="pt-0">
          <v-list-item-group v-model="activated" color="primary">
            <template v-for="(markup, index) in markupList">
              <!-- <v-divider v-if="index === 0" :key="markup.id"></v-divider> -->

              <v-hover :key="markup.markupId" v-slot="{ hover }">
                <v-list-item
                  class="markup-list-item"
                  :class="{ 'on-hover': hover || index === activated }"
                  @click="() => activate(markup, index)"
                >
                  <v-list-item-avatar class="my-auto" size="64" tile>
                    <v-img
                      v-if="markupThumbnails[markup.markupId]"
                      class="rounded"
                      :src="markupThumbnails[markup.markupId]"
                    ></v-img>
                    <v-img
                      v-else
                      class="rounded"
                      src="@/assets/image-not-found.png"
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="d-flex align-center">
                      [{{ markup.markupId }}] {{ markup.rfiTypeName }}
                      <v-spacer></v-spacer>
                      <v-chip
                        small
                        label
                        class="text-caption"
                        :color="getStatusColor(markup.statusId)"
                      >
                        {{ markup.markupStatusName }}
                      </v-chip>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      ><span class="text--primary">{{
                        markup.createdUserName
                      }}</span>
                      -
                      {{ markup.description }}
                    </v-list-item-subtitle>
                    <div class="d-flex justify-space-between align-center">
                      <a
                        class="text-caption"
                        @click.stop="() => startReadMore(markup)"
                        >read more</a
                      >
                      <div
                        class="
                          text-right text-caption
                          grey--text
                          text--darken-2
                        "
                      >
                        <sb-ui-time-cell
                          :time="markup.createdDate"
                          :isFromNow="false"
                        ></sb-ui-time-cell>
                      </div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-hover>
              <v-divider :key="markup.id"></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </perfect-scrollbar>
    </v-card>
    <v-card-actions class="d-flex justify-center">
      <v-pagination
        v-model="page"
        :length="maxPages"
        circle
        @input="getMarkupList"
      ></v-pagination>
    </v-card-actions>
    <v-expand-transition>
      <v-card
        v-if="readMore"
        class="transition-fast-in-fast-out read-more-panel"
        flat
        tile
      >
        <perfect-scrollbar :options="{ suppressScrollX: true }">
          <v-card-title>
            [{{ readMoreData.markupId }}] {{ readMoreData.rfiTypeName }}
            <v-chip
              small
              label
              class="text-caption ml-2"
              :color="getStatusColor(readMoreData.statusId)"
            >
              {{ readMoreData.markupStatusName }}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn icon @click="endReadMore">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pb-0">
            <div class="text-overline- mb-2">
              建立者: {{ readMoreData.createdUserName }}
            </div>
            <div class="text-caption mb-2 text-right">
              <sb-ui-time-cell
                :time="readMoreData.createdDate"
                :isFromNow="false"
              ></sb-ui-time-cell>
            </div>
            <div class="text-body-2 py-2">
              {{ readMoreData.description }}
            </div>
          </v-card-text>
        </perfect-scrollbar>
      </v-card>
    </v-expand-transition>
  </v-card>
</template>

<script>
import Vue from "vue";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { toDataSourceRequestString } from "@progress/kendo-data-query";
import Header from "@/components/NavHeader";

export default {
  name: "MarkupPanel",
  components: { PerfectScrollbar, Header },
  props: {
    bimset: {
      type: Object,
    },
  },

  data: () => ({
    ready: false,
    markupList: [],
    markupThumbnails: {},
    readMore: false,
    readMoreData: {},
    activated: null,
    searchText: null,
    pageLoading: false,
    page: 1,
    pageSize: 9,
    maxPages: 1,
  }),

  computed: {
    forgeVuer() {
      return window.forgeVuer["webimsync-forge-viewer"];
    },
    viewer() {
      return window.viewerService["webimsync-forge-viewer"].Viewer3D;
    },
    paginationQuery() {
      const filter = this.searchText
        ? {
            filters: [
              {
                field: "createdUserName",
                operator: "contains",
                value: this.searchText,
              },
              {
                field: "description",
                operator: "contains",
                value: this.searchText,
              },
              // 等之後有 string 版本的 markupId 再改
              // {
              //   field: "markupId",
              //   operator: "contains",
              //   value: this.searchText,
              // },
              {
                field: "markupStatusName",
                operator: "contains",
                value: this.searchText,
              },
              {
                field: "rfiTypeName",
                operator: "contains",
                value: this.searchText,
              },
            ],
            logic: "or",
          }
        : null;
      return toDataSourceRequestString({
        skip: (this.page - 1) * this.pageSize,
        take: this.pageSize,
        filter,
      });
    },
  },

  created() {
    this.getMarkupList();
  },

  methods: {
    onSearch(text) {
      this.searchText = text;
      this.getMarkupList();
    },
    getMarkupList() {
      Vue.prototype.$API.api.bim.projectsZonesMarkups._setVersion("v1.4");
      this.pageLoading = true;

      const handler = (defaults) => {
        const params = new URLSearchParams(this.paginationQuery);
        return {
          params,
          ...defaults,
        };
      };

      Vue.prototype.$API.api.bim.projectsZonesMarkups
        .getAll(this.bimset.projectId, this.bimset.zoneId, handler)
        .then((res) => {
          this.markupList = res.data.data;
          this.maxPages = Math.ceil(res.data.total / this.pageSize);
          this.pageLoading = false;

          res.data.data.forEach((e) => {
            this.getThumbnail(e.guid).then((thumbnail) =>
              this.$set(this.markupThumbnails, [e.markupId], thumbnail)
            );
          });
        });
    },
    getStatusColor(statusId) {
      switch (statusId) {
        case 0:
          return "secondary";
        case 1:
          return "primary";
        case 2:
          return "success";
      }
    },
    getThumbnail(markupId) {
      return new Promise((resolve) => {
        Vue.prototype.$API.api.bim.projectsMarkups
          .getThumbnail(this.bimset.projectId, markupId, (config) => {
            config.responseType = "blob";
            return config;
          })
          .then((res) => res.data)
          .then((blob) => {
            resolve(URL.createObjectURL(blob));
          })
          .catch(() => resolve(undefined));
      });
    },
    getPreview(markupId, photoId) {
      return new Promise((resolve) => {
        Vue.prototype.$API.api.bim.markups
          .getImage(markupId, photoId, (config) => {
            config.responseType = "blob";
            return config;
          })
          .then((res) => res.data)
          .then((blob) => {
            resolve(URL.createObjectURL(blob));
          })
          .catch(() => resolve(undefined));
      });
    },
    getAnnotations(markupId, photoId, annotationId) {
      return new Promise((resolve) => {
        Vue.prototype.$API.api.bim.markups
          .getSVG(markupId, photoId, annotationId, (config) => {
            config.responseType = "blob";
            return config;
          })
          .then((res) => res.data)
          .then((blob) => {
            resolve(URL.createObjectURL(blob));
          })
          .catch(() => resolve(undefined));
      });
    },
    startReadMore(markup) {
      this.readMoreData = markup;
      this.readMore = true;
    },
    endReadMore() {
      this.readMore = false;
      this.readMoreData = null;
    },
    activate(markup, index) {
      if (this.activated === index) {
        this.hideMarkup(markup.markupId);
        this.removeCutPlanes();
      } else {
        let annotations = markup.photo.annotations.map((e) =>
          this.getAnnotations(markup.guid, e.photoId, e.id).then((url) => ({
            id: e.id,
            url: url,
          }))
        );

        this.getPreview(markup.guid, markup.photo.id).then((url) => {
          Promise.all(annotations).then((svgs) => {
            this.showMarkup(
              markup.markupId,
              `[${markup.markupId}] ${markup.rfiTypeName}`,
              url,
              svgs
            );
          });
        });
        this.forgeVuer.setViewportByNavisworks(
          JSON.parse(markup.cameraJson),
          JSON.parse(markup.clipPlanesJson),
          false
        );
      }
    },
    showMarkup(id, title, thumbnail, svgs = []) {
      this.$forge.loadedExtensions["Syncobox.Forge.Extension.ImageViewer"].show(
        {
          id: id,
          title: title,
          src: thumbnail,
          svgs: svgs,
        }
      );
    },
    hideMarkup(id) {
      const exist =
        !!this.$forge.loadedExtensions["Syncobox.Forge.Extension.ImageViewer"]
          .panels[id];
      if (exist) {
        this.$forge.loadedExtensions[
          "Syncobox.Forge.Extension.ImageViewer"
        ].hide({ id: id });
      }
    },
    removeCutPlanes() {
      this.$forge.restoreState({ cutplanes: null });
    },
    toggleCloseEvent() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.markup-list-item {
  transition: opacity 0.4s ease-in-out;
}

.markup-list-item:not(.on-hover) {
  opacity: 1;
}

.read-more-panel {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
  height: 100%;
}

.ps {
  height: 100%;
  width: 100%;
}
</style>

<style>
.ps__rail-x,
.ps__rail-y {
  z-index: 5;
}
</style>
