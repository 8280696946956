<template>
  <v-card tile flat>
    <v-card
      class="d-flex justify-center align-center pa-2 mb-1"
      height="48"
      tile
      flat
    >
      <v-text-field
        v-if="!hideSearch"
        v-model="searchText"
        clearable
        dense
        flat
        solo
        hide-details
        outlined
        prepend-inner-icon="mdi-magnify"
        label="Search"
        @input="onSearch"
      ></v-text-field>
      <v-spacer v-if="hideSearch"></v-spacer>
      <v-btn icon class="ml-2" @click="onClose">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-card>
    <v-divider></v-divider>
  </v-card>
</template>

<script>
export default {
  name: "NavHeader",
  props: {
    hideSearch: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    searchText: "",
  }),
  methods: {
    onSearch(value) {
      this.$emit("search", value);
    },
    onClose(value) {
      this.$emit("close", value);
    },
  },
};
</script>
