import { render, staticRenderFns } from "./WeBIMSync.vue?vue&type=template&id=12cb05ee&scoped=true"
import script from "./WeBIMSync.vue?vue&type=script&lang=js"
export * from "./WeBIMSync.vue?vue&type=script&lang=js"
import style0 from "./WeBIMSync.vue?vue&type=style&index=0&id=12cb05ee&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12cb05ee",
  null
  
)

export default component.exports