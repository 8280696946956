<template>
  <v-card width="100%" height="100%" tile flat> </v-card>
</template>

<script>
export default {
  name: "LevelsExtension",
  data: () => ({
    extensionId: "Autodesk.AEC.LevelsExtension",
  }),
  computed: {
    viewer() {
      return window.viewerService["webimsync-forge-viewer"].Viewer3D;
    },
  },
  mounted() {
    if (this.$forge) {
      setTimeout(() => {
        this.activate(this.extensionId);
      }, 350);
    } else {
      this.$emit("initial:failed");
    }
  },
  beforeDestroy() {
    this.deactivate(this.extensionId);
  },
  methods: {
    activate(extensionId) {
      const extension = this.viewer.getExtension(extensionId);

      extension.levelsPanel.setVisible(true);
      this.styleOperator(
        extension.levelsPanel.container,
        extension.levelsPanel.closer,
        () => {
          this.$emit("close");
        }
      );
    },
    deactivate(extensionId) {
      const extension = this.viewer.getExtension(extensionId);
      if (!extension) return;
      extension.levelsPanel.setVisible(false);
    },
    styleOperator(container, closer, closeCallback) {
      container.style.height = "calc(100% - 20px)";
      container.style.top = "10px";
      container.style.left = "10px";
      container.style["max-height"] = "calc(100% - 10px)";
      closer.addEventListener("click", closeCallback);
    },
  },
};
</script>
