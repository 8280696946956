<template>
  <v-card width="100%" height="100%" tile flat> </v-card>
</template>

<script>
export default {
  name: "ModelStructure",
  data: () => ({
    extensionId: "Autodesk.ModelStructure",
  }),
  created() {
    if (this.$forge) {
      setTimeout(() => {
        this.activate(this.extensionId);
      }, 350);
    } else {
      this.$emit("initial:failed");
    }
  },
  beforeDestroy() {
    this.deactivate(this.extensionId);
  },
  methods: {
    activate(extensionId) {
      const extension = this.$forge.getExtension(extensionId);
      if (!extension) {
        this.$emit("initial:failed");
        return;
      }

      // if (!extension._modelstructure) {
      //   extension.restoreDefaultPanel();
      // }

      extension.activate();

      if (extension.isActive()) {
        const panel = extension._modelstructure;
        const callback = () => {
          this.$emit("close");
          this.deactivate();
        };
        this.styleOperator(panel.container, panel.closer, callback);
      } else {
        this.$emit("initial:failed");
      }
    },
    deactivate(extensionId) {
      const extension = this.$forge.getExtension(extensionId);
      if (!extension) return;
      extension.deactivate();
      extension.setModelStructurePanel(null);
      extension.restoreDefaultPanel();
      // extension._modelstructure.uninitialize();
      // extension._modelstructure = null;
    },
    styleOperator(container, closer, closeCallback) {
      container.style.height = "calc(100% - 20px)";
      container.style["max-height"] = "calc(100% - 10px)";
      closer.addEventListener("click", closeCallback);
    },
  },
};
</script>
