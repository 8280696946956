<template>
  <sb-loader />
</template>

<script>
export default {
  name: "SignoutCallback",
  mounted() {
    const path = window.localStorage.getItem("webimsyncUrlBeforeSignout");
    window.localStorage.removeItem("webimsyncUrlBeforeSignout");
    this.$router.push({ path });
  },
};
</script>
