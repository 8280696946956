<template>
  <v-menu transition="slide-y-transition" offset-y bottom>
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip bottom>
        <template #activator="{ on: tooltip }">
          <v-btn icon v-bind="attrs" v-on="{ ...menu, ...tooltip }">
            <v-icon>mdi-cog-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $tc("setting") }}</span>
      </v-tooltip>
    </template>
    <v-list dense width="360">
      <v-list-item v-if="darkMode" @click="() => updateTheme('light')">
        <v-list-item-title class="pa-2">
          <v-icon class="mr-2">mdi-white-balance-sunny</v-icon>
          {{ $tc("lightMode") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="lightMode" @click="() => updateTheme('dark')">
        <v-list-item-title class="pa-2">
          <v-icon class="mr-2">mdi-weather-night</v-icon>
          {{ $tc("darkMode") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="viewerReady" @click="toggleViewerSettingPanel">
        <v-list-item-title class="pa-2">
          <v-icon class="mr-2">mdi-hammer-wrench</v-icon>
          {{ $tc("forgeSetting") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="viewerReady" @click.stop="" @keydown.stop="">
        <v-list-item-title
          class="pa-2 d-flex justify-space-between align-center"
        >
          <div class="d-flex align-center">
            <v-icon class="mr-2">mdi-hexagram-outline</v-icon>
            {{ $tc("explodeMode") }}
          </div>
          <v-card
            width="50%"
            color="transparent"
            flat
            style="display: inline-flex"
          >
            <v-slider
              v-model="explodeScale"
              max="1"
              min="0"
              step="0.01"
              height="24"
              dense
              hide-details
              @mousedown.stop=""
              @click.stop=""
              @input="setExplode"
            ></v-slider>
          </v-card>
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="viewerReady" @click.stop="" @keydown.stop="">
        <v-list-item-title
          class="pa-2 d-flex justify-space-between align-center"
        >
          <div class="d-flex align-center">
            <v-icon class="mr-2">mdi-wifi-strength-outline</v-icon>
            {{ $tc("fovSetting") }}
          </div>
          <v-card
            width="50%"
            color="transparent"
            flat
            style="display: inline-flex"
          >
            <v-slider
              v-model="focalLength"
              max="200"
              min="10"
              height="24"
              dense
              hide-details
              @mousedown.stop=""
              @click.stop=""
              @input="setFocalLength"
            ></v-slider>
          </v-card>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "Setting",
  data: () => ({
    dialog: false,
    viewerReady: false,
    focalLength: 29,
    explodeScale: 0,
  }),
  computed: {
    lightMode() {
      return !this.$vuetify.theme.isDark;
    },
    darkMode() {
      return this.$vuetify.theme.isDark;
    },
  },
  mounted() {},
  methods: {
    initialize() {
      this.viewerReady = true;
      this.focalLength = this.$forge.getFocalLength();
      this.explodeScale = this.$forge.getExplodeScale();
    },
    updateTheme(mode) {
      const isDark = mode === "dark";

      this.$vuetify.theme.isDark = isDark;
      if (this.$forge) {
        this.$forge.setTheme(isDark ? "dark-theme" : "light-theme");
      }
    },
    toggleViewerSettingPanel() {
      this.$forge.showViewer3dOptions(true);
    },
    setFocalLength(value) {
      this.$forge.setFocalLength(value);
    },
    setExplode(scale) {
      this.$forge.explode(scale);
    },
  },
};
</script>
