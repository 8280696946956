import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
Vue.config.productionTip = false;

//shared-framework
import "@/plugins/shared-framework";

// shared-ui
import syncoboxsharedui from "@syncobox/syncobox-shared-ui";
Vue.use(syncoboxsharedui, { store });

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
