<template>
  <v-card width="100%" height="100%" tile flat>
    <Header :hide-search="true" @close="toggleCloseEvent" />
    <div class="ma-2" v-if="hierarchy.length === 0">此模型沒有房間資訊。</div>
    <v-treeview
      :items="hierarchy"
      open-on-click
      activatable
      hoverable
      return-object
      @update:active="onActive"
    ></v-treeview>
  </v-card>
</template>

<script>
import Header from "@/components/NavHeader";

export default {
  name: "Rooms",
  components: { Header },
  props: {
    urns: {
      type: Array,
    },
  },
  data: () => ({
    hierarchy: [],
  }),
  mounted() {
    if (this.$forge) {
      this.$forge.addEventListener(
        window.Autodesk.Viewing.MODEL_ADDED_EVENT,
        this.onModelAdded
      );
      const models = this.$forge.getAllModels();
      const rooms = models.map((model) => this.getAllRelatedItems(model));
      Promise.all(rooms).then((collections) => {
        collections = collections.filter((e) => e !== null);
        collections = collections.map((e) => this.buildHierarchy(e));
        this.hierarchy.push(...collections);
      });
    } else {
      this.$emit("initial:failed");
    }
  },
  beforeDestroy() {
    this.$forge.restoreState({ cutplanes: [] });
    this.$forge.fitToView(null, null, true);
    this.$forge.removeEventListener(
      window.Autodesk.Viewing.MODEL_ADDED_EVENT,
      this.onModelAdded
    );
  },
  methods: {
    onModelAdded(e) {
      this.getAllRelatedItems(e.model)
        .then((items) => {
          if (!items) return;

          const result = this.buildHierarchy(items);

          this.hierarchy.push(result);
        })
        .catch((err) => {
          console.log(err);
          this.onModelAdded(e);
        });
    },
    getRoomDbid(model) {
      return new Promise((resolve, reject) => {
        model.search("房間", resolve, reject, [
          "LcOaNode:LcOaSceneBaseClassUserName",
        ]);
      });
    },
    getBulkProperties(model, dbIds) {
      return new Promise((resolve, reject) => {
        model.getBulkProperties(dbIds, {}, resolve, reject);
      });
    },
    verifyRoom(model, dbIds) {
      return new Promise((resolve, reject) => {
        this.getBulkProperties(model, dbIds)
          .then((res) => {
            const rooms = res.filter((e) => {
              const type = e.properties.find(
                (prop) =>
                  prop.attributeName === "LcOaNode:LcOaSceneBaseClassUserName"
              );
              return type && type.displayValue === "房間";
            });

            const urn = model.getData().urn;
            const bimModelItemId = this.urns.find(
              (e) => e.urn === urn
            ).bimModelItemId;
            return rooms.map((e) => ({
              id: `${bimModelItemId}/${e.dbId}`,
              dbId: e.dbId,
              name: e.name,
              urn: urn,
              parent: model.getInstanceTree().getNodeParentId(e.dbId),
            }));
          })
          .then(resolve)
          .catch(reject);
      });
    },
    getNode(model, dbId) {
      const it = model.getInstanceTree();
      const urn = model.getData().urn;
      return {
        id: `${urn}/${dbId}`,
        dbId: dbId,
        name: it.getNodeName(dbId),
        urn: urn,
        parent: it.getNodeParentId(dbId),
      };
    },
    getParentList(items) {
      let parents = items.map((e) => e.parent);
      return parents.reduce((accumulate, current) => {
        return accumulate.includes(current) || current === 0
          ? accumulate
          : [...accumulate, current];
      }, []);
    },
    async getAllRelatedItems(model) {
      let roomIds = await this.getRoomDbid(model);
      if (roomIds.length === 0) return null;

      let items = await this.verifyRoom(model, roomIds);

      let parents = this.getParentList(items);
      while (parents.length > 0) {
        let parentNodes = parents.map((id) => this.getNode(model, id));
        items.push(...parentNodes);
        parents = this.getParentList(parentNodes);
      }

      return items;
    },
    buildHierarchy(items) {
      const root = items.find((e) => e.parent === 0);
      return this.collectChildren(root, items);
    },
    collectChildren(parent, candidates) {
      const children = candidates
        .filter((e) => e.parent === parent.dbId)
        .map((e) => this.collectChildren(e, candidates));
      return {
        ...parent,
        children: children.length > 0 ? children : null,
      };
    },
    onActive(activeItems) {
      const boxes = activeItems.map((item) => {
        const model = this.viewer
          .getAllModels()
          .find((e) => e.myData.urn === item.urn);
        return this.getBoundingBox(model, item.dbId);
      });

      if (boxes.length === 0) {
        this.$forge.restoreState({ cutplanes: [] });
        this.$forge.fitToView(null, null, true);
      } else {
        let sectionBox = boxes.reduce(
          (accumulate, current) => accumulate.union(current),
          new window.THREE.Box3()
        );
        sectionBox = sectionBox.expandByScalar(0.3);
        const sectionExt = this.$forge.getExtension("Autodesk.Section");
        sectionExt.setSectionBox(sectionBox);
        this.$forge.navigation.fitBounds(true, sectionBox, true);
      }
    },
    getBoundingBox(model, dbId) {
      let fragbBox = new window.THREE.Box3();
      let nodebBox = new window.THREE.Box3();
      let fragList = model.getFragmentList();
      model.getData().instanceTree.enumNodeFragments(
        dbId,
        (fragId) => {
          fragList.getWorldBounds(fragId, fragbBox);
          nodebBox.union(fragbBox);
        },
        false
      );
      return nodebBox;
    },
    toggleCloseEvent() {
      this.$emit("close");
    },
  },
};
</script>
