<template>
  <v-breadcrumbs
    v-if="!$vuetify.breakpoint.smAndDown"
    :items="breadcrumbs"
    divider="/"
  >
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item :href="item.href">
        <v-card tile flat color="transparent" class="" width="160">
          <v-select
            class="body-2"
            v-if="item.candidates"
            v-model="item.bind"
            :items="item.candidates"
            :item-text="item.text"
            :disabled="item.disable"
            item-value="id"
            return-object
            hide-details
            dense
            @input="item.onchange"
          >
            <template v-slot:item="e">
              {{ e.item.name }}
              <v-tooltip v-if="e.item.isAnyTranslated === false" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="pa-2"
                    small
                    color="warning"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-alert-circle-outline
                  </v-icon>
                </template>
                <span>{{ $tc("noAnyTranslated") }}</span>
              </v-tooltip>
            </template>
          </v-select>
          <div v-else class="d-flex jusitfy-center align-center">
            <span
              class="d-inline-block text-truncate"
              :class="{'text-decoration-underline': projcetLinkHover}"
              style="width: 100%; height: 100%"
              @mouseover="projcetLinkHover = true"
              @mouseout="projcetLinkHover = false"
              >{{ item.text }}</span
            >
          </div>
        </v-card>
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>

  <v-dialog v-else width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mx-2"
        color="secondary lighten-1"
        plain
        v-bind="attrs"
        v-on="on"
      >
        <v-icon class="mx-1">mdi-progress-clock</v-icon>
        {{ $tc("switch") }}{{ $tc("region") }} / {{ $tc("version") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title> {{ $tc("region") }} / {{ $tc("version") }} </v-card-title>

      <v-card-text>
        <v-container fluid>
          <v-row align="center">
            <v-col cols="6">
              <v-subheader> {{ $tc("project") }} </v-subheader>
            </v-col>
            <v-col cols="6">
              {{ breadcrumbs[0].text }}
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="6">
              <v-subheader> {{ $tc("region") }} </v-subheader>
            </v-col>
            <v-col cols="6">
              <v-select
                class="body-2"
                v-if="breadcrumbs[1].candidates"
                v-model="breadcrumbs[1].bind"
                :items="breadcrumbs[1].candidates"
                :item-text="breadcrumbs[1].text"
                :disabled="breadcrumbs[1].disable"
                item-value="id"
                return-object
                hide-details
                dense
                @input="breadcrumbs[1].onchange"
              >
                <template v-slot:item="e">
                  {{ e.item.name }}
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="6">
              <v-subheader> {{ $tc("version") }} </v-subheader>
            </v-col>
            <v-col cols="6">
              <v-select
                class="body-2"
                v-if="breadcrumbs[2].candidates"
                v-model="breadcrumbs[2].bind"
                :items="breadcrumbs[2].candidates"
                :item-text="breadcrumbs[2].text"
                :disabled="breadcrumbs[2].disable"
                item-value="id"
                return-object
                hide-details
                dense
                @input="breadcrumbs[2].onchange"
              >
                <template v-slot:item="e">
                  {{ e.item.name }}
                  <v-tooltip v-if="!e.item.isAnyTranslated" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="pa-2"
                        small
                        color="warning"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-alert-circle-outline
                      </v-icon>
                    </template>
                    <span>{{ $tc("noAnyTranslated") }}</span>
                  </v-tooltip>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Breadcrumbs",
  props: {
    currentProject: {
      type: Object,
      default: () => undefined,
    },
    currentZone: {
      type: Object,
      default: () => undefined,
    },
    currentBimset: {
      type: Object,
      default: () => undefined,
    },
    zoneList: {
      type: Array,
      default: () => [],
    },
    bimsetList: {
      type: Array,
      default: () => [],
    },
    disableZoneList: {
      type: Boolean,
      default: () => false,
    },
    disableBimsetList: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    projcetLinkHover: false
  }),
  computed: {
    breadcrumbs() {
      return [
        {
          bind: this.currentProject,
          id: this.currentProject ? this.currentProject.id : undefined,
          text: this.currentProject ? this.currentProject.name : undefined,
          disable: false,
          href: this.currentProject ? `${process.env.VUE_APP_PORTAL_URL}/project/${this.currentProject.id}/overview?companyId=${this.$store.state.auth.userInfo.companyId}` : undefined,
        },
        {
          bind: this.currentZone,
          id: this.currentZone ? this.currentZone.id : undefined,
          text: "name",
          disable: this.disableZoneList,
          href: null,
          candidates: this.zoneList,
          onchange: this.onZoneChange,
        },
        {
          bind: this.currentBimset,
          id: this.currentBimset ? this.currentBimset.id : undefined,
          text: "name",
          disable: this.disableBimsetList,
          href: null,
          candidates: this.bimsetList,
          onchange: this.onBimsetChange,
        },
      ];
    },
  },
  methods: {
    onZoneChange(e) {
      this.$emit("updated:zone", e);
    },
    onBimsetChange(e) {
      this.$emit("updated:bimset", e);
    },
  },
};
</script>
