<template>
  <v-card
    width="100%"
    height="100%"
    :loading="!ready"
    :disabled="!ready"
    tile
    flat
  >
    <Header :hide-search="true" @close="onClose" />

    <v-list subheader one-line flat>
      <v-subheader>{{ $tc("chooseCompareVersion") }}</v-subheader>

      <v-select
        v-model="compareVersion"
        class="mx-4"
        :items="bimSetList.filter((e) => e.id != bimset.id)"
        item-value="id"
        item-text="name"
        :placeholder="$tc('chooseAVersion')"
        :loading="fetchingBitSetList"
        :disabled="clearable"
        :error-messages="errorMsg"
        @input="updateCompareModels"
      ></v-select>
    </v-list>

    <v-toolbar dense flat class="d-flex flex justify-end align-center">
      <v-btn
        depressed
        class="mx-2"
        color="error"
        :disabled="!clearable"
        @click="clear"
      >
        {{ $tc("clear") }}
      </v-btn>

      <v-btn
        depressed
        class="mx-2"
        color="primary"
        :disabled="loadingPersent < 100 || clearable"
        :loading="loadingPersent < 100"
        @click="apply"
      >
        {{ $tc("compare") }}
        <template v-slot:loader>
          <span> {{ loadingPersent }}% </span>
        </template>
      </v-btn>
    </v-toolbar>
  </v-card>
</template>

<script>
import Vue from "vue";
import Header from "@/components/NavHeader";

export default {
  name: "Compare",
  components: { Header },
  props: {
    bimset: {
      type: Object,
    },
  },
  data: () => ({
    compareVersion: null,
    originModels: [],
    ready: false,
    fetchingBitSetList: false,
    errorMsg: "",
    clearable: false,
    loadingPersent: 100,
    bimSetList: [],
  }),
  mounted() {
    if (
      this.$forge &&
      this.$forge.model &&
      this.$forge.model.getData().instanceTree
    ) {
      this.reloadSetting();
      this.ready = true;
      this.getBimSetList(this.bimset.projectId, this.bimset.zoneId);
    } else {
      this.$emit("initial:failed");
    }
  },
  beforeDestroy() {
    // vue 狀態，預防切換工具時造成設定遺失
    window.compareData = {
      clearable: this.clearable,
      compareBubbles: this.compareBubbles,
      compareModelIds: this.compareModelIds,
      histroyModelIds: this.histroyModelIds,
      compareVersion: this.compareVersion,
    };
  },
  methods: {
    reloadSetting() {
      if (window.compareData) {
        this.clearable = window.compareData.clearable;
        this.compareBubbles = window.compareData.compareBubbles;
        this.compareModelIds = window.compareData.compareModelIds;
        this.histroyModelIds = window.compareData.histroyModelIds;
        this.compareVersion = window.compareData.compareVersion;
      } else {
        this.clearable = false;
        this.compareBubbles = [];
        this.compareModelIds = [];
        this.histroyModelIds = [];
        this.compareVersion = null;
      }
    },
    getModelName(node) {
      if (!node.parent.parent) {
        return node.data.name;
      } else {
        return this.getModelName(node.parent);
      }
    },
    onClose() {
      this.$emit("close");
    },
    clear() {
      if (this.$forge.getExtension("Autodesk.DiffTool")) {
        this.$forge.getExtension("Autodesk.DiffTool").cleanup();
        this.resetCompareModels();
        this.compareVersion = null;
        this.clearable = false;
        this.errorMsg = "";
      }
    },
    apply() {
      this.errorMsg = "";
      const models = this.$forge.getAllModels();
      this.loadingPersent = 0;

      // debug
      console.log(
        this.compareModelIds.sort((a, b) => a - b),
        this.compareBubbles.sort((a, b) => a.id - b.id)
      );
      console.log(
        "original: ",
        models
          .filter((e) => !this.histroyModelIds.includes(e.id))
          .sort((a, b) => a.id - b.id)
      );
      console.log(
        "diff: ",
        models
          .filter((e) => this.compareModelIds.includes(e.id))
          .sort((a, b) => a.id - b.id)
      );

      var config = {
        availableDiffModes: ["overlay", "sidebyside"],
        diffMode: "overlay",
        headless: false,
        diffModels: models.filter((e) => this.compareModelIds.includes(e.id)),
        primaryModels: models.filter(
          (e) => !this.histroyModelIds.includes(e.id)
        ),
        mimeType: "application/vnd.autodesk.revit",
        versionA: `Ver. ${this.bimset.version}`,
        versionB: `${
          this.bimSetList.find((e) => e.id === this.compareVersion).name
        }`,
        hotReload: false,
        // hideModeSwitchButton: false,
        container: this.$forge.container, // 不給會有問題
        useSplitScreenExtension: true,
        progress: (percent) => {
          this.loadingPersent = parseInt(percent);
          if (percent >= 100) {
            this.clearable = true;
          }
        },
        onDiffModeChanged: (a) => {
          console.log(a);
          if (a === "sidebyside") {
            // document
            //   .querySelectorAll("#guiviewer3d-toolbar")
            //   .forEach((e) => (e.hidden = true));
            this.$forge
              .getExtension("Autodesk.DiffTool")
              .changesPanel.container.dispatchEvent(
                new CustomEvent("dockChanged")
              );
          } else if (a === "overlay") {
            document
              .querySelectorAll(".adsk-control .adsk-control-group")
              .forEach((e) => (e.hidden = true));

            if (document.querySelector("#diff-tool-group-show-diff"))
              document.querySelector(
                "#diff-tool-group-show-diff"
              ).hidden = false;
            if (document.querySelector("#diff-tool-button-comboSubMenu"))
              document.querySelector(
                "#diff-tool-button-comboSubMenu"
              ).hidden = false;
          } else {
            document
              .querySelectorAll(".adsk-control .adsk-control-group")
              .forEach((e) => (e.hidden = false));
          }
        },
        onInitialized: () => {
          this.$forge.unloadExtension("Autodesk.AEC.Minimap3DExtension");
        },
        onExit: () => {
          this.$forge.loadExtension("Autodesk.AEC.Minimap3DExtension");
          this.clear();
        },
        errorCB: (e) => {
          this.errorMsg = e;
          this.loadingPersent = 100;
          this.$forge.unloadExtension("Autodesk.DiffTool");
        },
      };

      this.$aggregateView
        .setNodes(
          [...config.primaryModels, ...config.diffModels].map((e) =>
            e.getDocumentNode()
          )
        )
        .then(() => {
          this.$forge.loadExtension("Autodesk.DiffTool", config);
        });
    },
    getBimSetList(projectId, zoneId) {
      this.fetchingBitSetList = true;

      Vue.prototype.$API.api.bim.projectsZonesBIMSets._setVersion("v1.4");
      return Vue.prototype.$API.api.bim.projectsZonesBIMSets
        .getBIMSets(projectId, zoneId)
        .then((res) => {
          this.bimSetList = res.data.map((e) => ({
            id: e.id,
            name: `Ver. ${e.version}  ${!e.isAnyTranslated ? "(Empty)" : ""}`,
          }));
          this.fetchingBitSetList = false;
        })
        .catch(() => {
          this.bimSetList = [];
        });
    },
    resetCompareModels() {
      if (!this.compareBubbles) {
        this.compareBubbles = [];
      }

      if (!this.compareModelIds) {
        this.compareModelIds = [];
      }

      if (!this.histroyModelIds) {
        this.histroyModelIds = [];
      }

      // if (this.compareBubbles.length > 0 && this.compareModelIds.length > 0) {
      //   while (this.compareBubbles.length > 0) {
      //     let e = this.compareBubbles.pop();
      //     this.$aggregateView.unload(e);
      //   }
      // }

      this.compareModelIds = [];

      if (this.$forge.getExtension("Autodesk.DiffTool")) {
        this.$forge.unloadExtension("Autodesk.DiffTool");
      }

      this.errorMsg = "";
    },
    updateCompareModels(id) {
      this.ready = false;

      this.resetCompareModels();

      Vue.prototype.$API.api.bim.projectsZonesBIMSets._setVersion("v1.4");
      return Vue.prototype.$API.api.bim.projectsZonesBIMSets
        .getBIMSetById(this.bimset.projectId, this.bimset.zoneId, id)
        .then((res) => {
          let urns =
            res.data.bimVersions && res.data.bimVersions.length > 0
              ? res.data.bimVersions
                  .filter((e) => e.derivativeId)
                  .map((e) => ({
                    urn: e.derivativeId,
                    viewable: e.defaultViewables,
                    bimModelItemId: e.itemId,
                  }))
              : [];

          this.urnToModels(urns).then((models) => {
            this.compareBubbles = models;

            const tasks = models.map((bubble) =>
              this.$aggregateView.load(bubble).then((model) => model.id)
            );

            Promise.all(tasks).then((ids) => {
              this.compareModelIds.push(...ids);
              this.histroyModelIds.push(...ids);
              setTimeout(() => {
                this.ready = true;
              }, 200);
            });
          });
        });
    },
    urnToModels(urns) {
      const loadManifest = (urn) => {
        return new Promise((resolve, reject) => {
          const onDocumentLoadSuccess = (doc) => {
            doc.downloadAecModelData(() => {
              resolve(doc);
            });
          };

          const onDocumentLoadError = (errorCode) => {
            return reject(errorCode);
          };

          if (urn.indexOf("adsk") != -1) {
            urn = `urn:${String.btoa(urn)}`;
          } else if (urn.indexOf("urn") == -1) {
            urn = `urn:${urn}`;
          }

          window.Autodesk.Viewing.Document.load(
            urn,
            onDocumentLoadSuccess,
            onDocumentLoadError
          );
        });
      };

      const task = urns.map((e) => loadManifest(e.urn));

      return new Promise((resolve) => {
        Promise.all(task).then((models) => {
          const geometries = models.map((e) =>
            e.getRoot().getDefaultGeometry()
          );
          resolve(geometries);
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

<style lang="scss">
#DiffToolChangesPanel .diffTitleBarButton.diffExport {
  display: none;
}

#DiffToolChangesPanel .diffTitleBarButton.diffPanelDock {
  display: none;
}
</style>
