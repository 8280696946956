import Vue from "vue";
import store from "@/store";
import router from "@/router";
import shared from "@syncobox/syncobox-shared";

import "@syncobox/syncobox-shared/dist/syncobox-shared.css";

const apiConfig = {
  identityBaseUrl: process.env.VUE_APP_IDENTITY_BASE_URL,
  redirectBaseUrl: process.env.VUE_APP_REDIRECT_BASE_URL,
  mainBaseUrl: process.env.VUE_APP_MAIN_BASE_URL,
  bimBaseUrl: process.env.VUE_APP_BIM_BASE_URL,
  token: localStorage.getItem("oidc_access_token"),
};

//auth
// ref: https://github.com/IdentityModel/oidc-client-js/wiki
const oidc = {
  authority: process.env.VUE_APP_IDENTITY_BASE_URL,
  clientId: "portal-spa",
  redirectUri: process.env.VUE_APP_REDIRECT_BASE_URL + "/callback",
  post_logout_redirect_uri:
    process.env.VUE_APP_REDIRECT_BASE_URL + "/signout-callback",
  responseType: "code",
  scope: `openid profile offline_access pano:all webimsync:all`, //openid profile offline_access
  silentRedirectUri:
    process.env.VUE_APP_REDIRECT_BASE_URL + "/silent-refresh.html",
  automaticSilentRenew: true, // If true oidc-client will try to renew your token when it is about to expire
  automaticSilentSignin: false, // If true vuex-oidc will try to silently signin unauthenticated users on public routes. Defaults to true
  silentRequestTimeout: 10000, // (number, default: 10000) milliseconds
  accessTokenExpiringNotificationTime: 5, //Number (in seconds) default is 60
  monitorSession: true,
  checkSessionInterval: 2000,
  revokeAccessTokenOnSignout: false,
  includeIdTokenInSilentRenew: true,
  staleStateAge: 300, // Number (in seconds)
  extraQueryParams: { resource: "some_identifier" },
};

const options = { store, router, apiConfig, oidc };
Vue.use(shared, options);
