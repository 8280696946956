class DrawingPanel extends window.Autodesk.Viewing.UI.DockingPanel {
  constructor(view, parentContainer, id, title, drawing, x = 10, y = 10) {
    super(parentContainer, id, title, {
      drawing,
      addFooter: true,
    });

    this.targetView = view;
    this.mapView = null;

    this.container.style.height = "450px";
    this.container.style.width = "600px";
    this.container.style.resize = "none";
    this.container.style.left = x + "px";
    this.container.style.top = y + "px";
  }

  initialize() {
    super.initialize();

    this.footer.style["z-index"] = 2;

    this.context = document.createElement("div");
    this.context.style.width = "100%";
    this.context.style.height = "calc(100% - 70px)"; // 100% - 50px (title bar) - 20px (footer)
    this.container.appendChild(this.context);

    setTimeout(() => {
      this.mountViewer();
    }, 100);
  }

  mountViewer() {
    const self = this;
    this.mapView = new window.Autodesk.Viewing.AggregatedView();
    this.mapView.init(this.context).then(() => {
      this.mapView.setNodes(this.options.drawing);
      this.mapView.viewer.container.style.height = "calc(100% - 70px)";

      this.mapView.viewer.addEventListener(
        window.Autodesk.Viewing.TOOLBAR_CREATED_EVENT,
        () => {
          self.hideUselessTools(self.mapView.viewer);
        }
      );

      this.mapView.viewer
        .loadExtension("Autodesk.AEC.DropMeExtension")
        .then((e) => {
          e._onDrop = (pos, dir) => {
            e._showDrop(1);
            if (pos && dir) {
              let camera = {
                position: pos,
                target: pos.clone().add(dir),
                up: self.targetView.viewer.navigation.getWorldUpVector(),
                isPerspective: true, // Note that DropMeTool usually produces "inside" views, which are not possible with ortho cameras
              };
              console.log(camera);
              self.targetView.setCameraGlobal(camera);
            }
          };
        });

      this.mapView.viewer.resize();
      this.footerInstance.resizeCallback = () => {
        this.mapView.viewer.resize();
        this.resizeHandler();
      };
    });
    window.testviewer = this.mapView.viewer;
  }

  resizeHandler() {}

  hideUselessTools(viewer) {
    const hideSetting = setInterval(() => {
      const ctrl = viewer.toolbar.getControl("settingsTools");
      if (ctrl) {
        ctrl.container.hidden = true;
        clearInterval(hideSetting);
      }
    }, 10);

    const levelSetting = setInterval(() => {
      const ctrl = viewer.toolbar
        .getControl("modelTools")
        .getControl("toolbar-levelsTool");
      if (ctrl) {
        ctrl.container.hidden = true;
        clearInterval(levelSetting);
      }
    }, 10);

    const cameraSetting = setInterval(() => {
      const ctrl = viewer.toolbar
        .getControl("navTools")
        .getControl("toolbar-cameraSubmenuTool");
      if (ctrl) {
        ctrl.container.hidden = true;
        clearInterval(cameraSetting);
      }
    }, 10);

    const zoomSetting = setInterval(() => {
      const ctrl = viewer.toolbar
        .getControl("navTools")
        .getControl("toolbar-zoomTools");
      if (ctrl) {
        ctrl.container.hidden = true;
        clearInterval(zoomSetting);
      }
    }, 10);
  }
}

export default DrawingPanel;
