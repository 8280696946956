<template>
  <div style="position: relative; width: 100%; height: 100%">
    <v-navigation-drawer
      id="component_container"
      v-model="sidebar"
      :width="drawerWidth"
      height="calc( 100% - 48px)"
      app
      style="margin-left: 76px; margin-top: 48px; min-width: 360px"
    >
      <component
        v-if="viewerReady && toolComponent"
        :is="toolComponent"
        :bimset="bimset"
        :urns="urns"
        @close="closeHandler"
        @initial:failed="initialFailed"
      ></component>
    </v-navigation-drawer>
    <div
      style="
        position: relative;
        width: calc(100% - 76px);
        height: 100%;
        margin-left: 76px;
      "
    >
      <forge-vuer
        v-if="urns.length > 0"
        ref="forgeVuer"
        id="webimsync-forge-viewer"
        :getAccessToken="getForgeTokenFunction()"
        :urn="urns"
        :options="forgeVuerOptions"
        :extensions="preloadExtensions"
        @viewerStarted="viewerStarted"
        style="width: 100%; height: 100%"
      ></forge-vuer>

      <v-card
        v-else-if="accessDenied"
        width="100%"
        height="100%"
        tile
        flat
        class="d-flex flex-column justify-center align-center"
      >
        <v-img
          class="ma-10"
          aspect-ratio="1"
          max-width="120"
          width="120"
          max-height="120"
          height="120"
          src="@/assets/block.svg"
        ></v-img>
        <div class="display-1" :class="{ 'white--text': theme.isDark }">
          {{ $tc("accessDenied") }}
        </div>
      </v-card>

      <v-card
        v-else
        width="100%"
        height="100%"
        tile
        flat
        class="d-flex flex-column justify-center align-center transparent"
      >
        <v-img
          class="ma-10"
          aspect-ratio="1"
          max-width="120"
          width="120"
          max-height="120"
          height="120"
          src="@/assets/alert.svg"
        ></v-img>
        <span class="display-1" :class="{ 'white--text': theme.isDark }">{{
          $tc("emptyBimSet")
        }}</span>
      </v-card>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { ForgeVuer } from "@syncobox/syncobox-bim";
import Levels from "@/components/tools/Levels.vue";
import Markups from "@/components/tools/Markups.vue";
import Rooms from "@/components/tools/Rooms.vue";
import Sheets from "@/components/tools/Sheets.vue";
import StructureTree from "@/components/tools/StructureTree.vue";
import Viewables from "@/components/tools/Viewables.vue";
import Viewpoints from "@/components/tools/Viewpoints.vue";
import Compare from "@/components/tools/Compare.vue";

export default {
  name: "ForgeLayout",
  components: {
    ForgeVuer,
    Levels,
    Markups,
    Rooms,
    Sheets,
    StructureTree,
    Viewables,
    Viewpoints,
    Compare,
  },
  props: {
    bimset: {
      required: true,
      type: Object,
    },
    activatedTool: {
      required: false,
      type: Object,
    },
  },
  data: () => ({
    viewerReady: false,
    drawerWidth: 360,
    toolComponent: null,
    sidebar: false,
  }),
  computed: {
    accessDenied() {
      return this.bimset.bimVersions.length === 0;
    },
    urns() {
      return this.bimset.bimVersions && this.bimset.bimVersions.length > 0
        ? this.bimset.bimVersions
            .filter((e) => e.derivativeId)
            .map((e) => ({
              urn: e.derivativeId,
              viewable: e.defaultViewables,
              bimModelItemId: e.itemId,
            }))
        : [];
    },
    forgeVuerOptions() {
      return {
        AutodeskViewingOptions: {
          api: "streamingV2",
          env: "AutodeskProduction2",
        },
        AggregateViewOptions3d: {
          viewerConfig: {
            disableBimWalkInfoIcon: true,
            theme: `${this.$vuetify.theme.isDark ? "dark" : "light"}-theme`,
            loaderExtensions: { svf: "Autodesk.MemoryLimited" },
          },
          headlessViewer: false,
        },
      };
    },
    theme() {
      return this.$vuetify.theme;
    },
    preloadExtensions() {
      return {
        "Syncobox.Forge.Extension.ImageViewer": {
          url: `https://cdn.syncobox.com/extensions/Syncobox.Forge.Extension.ImageViewer@0.1.0.js`,
          options: {
            dark: this.theme.isDark,
          },
        },
        "Syncobox.Forge.Extension.ViewableBrowser": {
          url: "https://cdn.syncobox.com/extensions/Syncobox.Forge.Extension.ViewableBrowser@0.1.3.js",
          options: {
            urns: this.urns,
          },
        },
      };
    },
  },
  mounted() {
    this.initResizeHandler();
  },
  methods: {
    getForgeTokenFunction: () => {
      return function (onSuccess) {
        Vue.prototype.$API.api.main.forgeToken
          .get()
          .then((res) => onSuccess(res.data.access_token, res.data.expires_in))
          .catch((error) => console.log(error));
      };
    },
    closeHandler() {
      this.sidebar = false;
      this.$emit("tool:deactivated");
    },
    initialFailed() {
      this.sidebar = false;
      this.toolComponent = null;
      this.$emit("tool:deactivated");
    },
    initResizeHandler() {
      const nav = document.getElementById("component_container");
      const handler = nav.querySelector(".v-navigation-drawer__border");
      handler.style.cursor = "col-resize";
      handler.style.width = "3px";

      const moveEvent = (e) => {
        this.drawerWidth = `${e.clientX - nav.offsetLeft}px`;
      };

      handler.addEventListener("mousedown", () => {
        window.addEventListener("mousemove", moveEvent, false);
        nav.style["transition-duration"] = "0s";

        window.addEventListener(
          "mouseup",
          () => {
            window.removeEventListener("mousemove", moveEvent, false);
            nav.style["transition-duration"] = "";
          },
          { once: true }
        );
      });
    },
    viewerStarted(viewer) {
      this.viewerReady = true;
      Vue.prototype.$forge = viewer;
      Vue.prototype.$aggregateView = this.$refs.forgeVuer.viewerService.View;
      this.$emit("ready", viewer);

      document.querySelector("#webimsync-forge-viewer").style.width = "100%";

      viewer.addEventListener(
        window.Autodesk.Viewing.TOOLBAR_CREATED_EVENT,
        () => {
          this.customToolbar(viewer);
        }
      );

      viewer
        .getExtension("Autodesk.AEC.Minimap3DExtension")
        .initLayoutIfNeeded();

      viewer
        .getExtension("Autodesk.AEC.Minimap3DExtension")
        .initMapViewerIfNeeded();

      viewer
        .getExtension("Autodesk.AEC.Minimap3DExtension")
        .layout.mapWidgetRoot.addEventListener(
          "transitionend",
          () => this.hide3dModelsInMap(viewer),
          { once: true }
        );

      // disable the ground shadow to boost the performance while loading the big model
      viewer.setGroundShadow(false);
    },
    customToolbar(viewer) {
      // make the toolbar vertical
      // viewer.toolbar.container.classList.add("adsk-toolbar-vertical");

      const hideTools = {
        navTools: ["toolbar-cameraSubmenuTool"],
        modelTools: ["toolbar-levelsTool", "toolbar-explodeTool"],
        settingsTools: [
          // "toolbar-modelStructureTool",
          "toolbar-settingsTool",
          "toolbar-fullscreenTool",
        ],
      };

      Object.keys(hideTools).forEach((control) => {
        hideTools[control].forEach((item) => {
          let task = setInterval(() => {
            const ctrl = viewer.toolbar.getControl(control).getControl(item);
            if (ctrl) {
              ctrl.container.hidden = true;
              clearInterval(task);
            }
          }, 10);
        });
      });

      const hideVerticalToolbar = setInterval(() => {
        const ctrl = viewer.verticalToolbar;
        if (ctrl) {
          ctrl.container.hidden = true;
          clearInterval(hideVerticalToolbar);
        }
      }, 10);
    },

    // < ---------- customize minimap ---------- >

    hide3dModelsInMap(viewer) {
      const toggle = document.createElement("div");
      toggle.setAttribute("class", "adsk-control adsk-button inactive");
      toggle.setAttribute(
        "style",
        "width: 20px; height: 20px; padding: 3px 8px; "
      );

      const icon = document.createElement("i");
      icon.setAttribute("class", "v-icon notranslate mdi mdi-video-3d-off");
      icon.setAttribute("style", "width: 20px; height: 20px;");

      toggle.appendChild(icon);

      toggle.addEventListener("click", () => {
        if (
          viewer
            .getExtension("Autodesk.AEC.Minimap3DExtension")
            .mapViewer.getExtension("Autodesk.AEC.TopViewRendererExtension")
            .topViewViewer.areAllVisible()
        ) {
          viewer
            .getExtension("Autodesk.AEC.Minimap3DExtension")
            .mapViewer.getExtension("Autodesk.AEC.TopViewRendererExtension")
            .topViewViewer.hideAll();

          viewer
            .getExtension("Autodesk.AEC.Minimap3DExtension")
            .mapViewer.getExtension("Autodesk.AEC.TopViewRendererExtension")
            .topViewViewer.setGhosting(false);

          toggle.classList.add("active");
          toggle.classList.remove("inactive");
          toggle.style.border = "solid 1px #00bfff";
        } else {
          viewer
            .getExtension("Autodesk.AEC.Minimap3DExtension")
            .mapViewer.getExtension("Autodesk.AEC.TopViewRendererExtension")
            .topViewViewer.showAll();

          toggle.classList.add("inactive");
          toggle.classList.remove("active");
          toggle.style.border = "none";
        }
      });

      const container = viewer.getExtension("Autodesk.AEC.Minimap3DExtension")
        .layout.mapWidgetHeader;

      container.insertBefore(toggle, container.lastElementChild);
    },
  },
  watch: {
    activatedTool: {
      immediate: true,
      deep: false,
      handler(value) {
        this.toolComponent = value ? value.toolName : null;
        this.sidebar = value ? value.container : false;
      },
    },
  },
};
</script>

<style>
/* hide the vertical toolbar from viewable browser extension */
#verticalDivToolbar {
  display: none;
}
.forge-vuer-container {
  width: 100%;
  height: 100%;
  position: relative;
}
.forge-vuer-viewer-display {
  height: 100%;
}
</style>

<style lang="scss">
// for markup viewer

.v-window__container {
  height: 100% !important;
}

.button-edit {
  z-index: 1000;
}

.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}

.toolbar-editor {
  position: absolute;
  bottom: 0;
  padding-bottom: 4px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translate(-50%, 0);
  width: fit-content;
  max-width: 100%;
  height: auto;
  // z-index: 105;
  max-height: 100%;
}

.toolbar-body {
  padding: 0;
  overflow: hidden;
  transition: visibility 0s, width 0.3s linear, height 0.25s linear;
  -webkit-transition: visibility 0s, width 0.1s linear, height 0.25s linear;
  height: 500px;
  height: auto;
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.textGroup {
  line-height: 1;
}

.text-textarea {
  background-color: #ffffff60;
  resize: none;
  overflow: hidden;
  min-height: 1.2em;
  min-width: 100px;
  width: 101%;
  height: 100%;
  color: transparent;
  outline: rgb(0, 0, 0) solid 0px;
  box-sizing: border-box;
}

.text-textarea::placeholder {
  color: #2e2e2e;
}

.v-hidden {
  visibility: hidden;
}

.preview-section {
  height: 75px;
}

.preview-square-opacity {
  width: 95%;
  height: 75px;
  margin: 0 auto;
  border-radius: 0.25em;
}

.preview-square-background {
  top: -75px;
  position: relative;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGElEQVQYlWNgYGCQwoKxgqGgcJA5h3yFAAs8BRWVSwooAAAAAElFTkSuQmCC)
    repeat;
}

.preview-underline-section {
  position: relative;
}

.preview-underline-background {
  position: relative;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGElEQVQYlWNgYGCQwoKxgqGgcJA5h3yFAAs8BRWVSwooAAAAAElFTkSuQmCC)
    repeat;
  height: 5px;
}

.preview-section-text {
  text-align: center;
  line-height: 75px;
  min-height: 75px;
  margin: 0px;
  vertical-align: middle;
  width: 100%;
  text-align: center;
}

.color-underline {
  width: 32px !important;
  height: 5px !important;
}

.wb-image {
  max-height: 100%;
  max-width: 100%;
}

.v-content__wrap {
  height: 100%;
}

.wb-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.wb-svg {
  position: absolute;
  left: 0;
  top: 0;
}

.toolbar-editor > .toolbar-body > * {
  margin-top: 4px;
  margin-left: 4px;
  margin-right: 4px;
  flex-grow: 0;
}
</style>
