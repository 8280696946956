import Vue from "vue";
import VueRouter from "vue-router";
import WeBIMSyncLayout from "@/views/WeBIMSync.vue";
import SignOutCallback from "@/views/SignOutCallback";
import Error404 from "@/views/404.vue";
import store from "@/store";
import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";

Vue.use(VueRouter);

const routes = [
  {
    path: "/signout-callback",
    name: "SignOutCallback",
    component: SignOutCallback,
  },
  {
    path: "/project/:projectId/zone/:zoneId/bimset/:bimsetId",
    name: "WeBIMSync",
    component: WeBIMSyncLayout,
    props: (route) => ({
      defalutProjectId: route.params.projectId,
      defalutZoneId: route.params.zoneId,
      defalutBimSetId: route.params.bimsetId,
    }),
  },
  {
    path: "/pages/404",
    name: "Error404",
    component: Error404,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, "oidcStore"));

export default router;
