<template>
  <v-card width="100%" height="100%" :loading="!ready" tile flat>
    <Header :hide-search="true" @close="toggleCloseEvent" />
    <div class="ma-2" v-if="hierarchy.length === 0">此模型沒有圖紙資訊。</div>
    <v-treeview
      :items="hierarchy"
      :active.sync="activedItems"
      item-key="guid"
      activatable
      multiple-active
      hoverable
      open-on-click
      return-object
      @update:active="sheetsActive"
    >
      <template v-slot:append="{ item }">
        <v-switch
          v-if="!item.children"
          v-model="item.hyperModeling"
          flat
          dense
          @change="(e) => (e ? addToForge(item) : removeFromForge(item))"
          @click.stop=""
        ></v-switch>
      </template>
    </v-treeview>
  </v-card>
</template>

<script>
import Header from "@/components/NavHeader";

export default {
  name: "Sheets",
  components: { Header },
  props: {
    urns: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    hierarchy: [],
    activedItems: [],
    ready: false,
  }),
  mounted() {
    if (this.$forge && this.$forge.toolbar) {
      this.initialize();
    } else {
      this.$emit("initial:failed");
    }
  },
  beforeDestroy() {
    this.$forge.removeEventListener(
      window.Autodesk.Viewing.TOOLBAR_CREATED_EVENT,
      this.initialize
    );
  },
  methods: {
    initialize() {
      this.ready = true;
      this.$forge.loadExtension("Autodesk.AEC.Hypermodeling").then(() => {
        this.hierarchy = this.getHierarchy();
        this.updateActiveState();
      });
    },

    getAvailableSheet(floor) {
      const sheets = this.$forge
        .getExtension("Autodesk.AEC.Hypermodeling")
        .getAvailableSheetsForLevel(floor);

      return sheets.map((e, index) => ({
        guid: `${e.node.data.guid}-${floor}-${index}`,
        name: e.node.data.name,
        index: index,
        level: floor,
        hyperModeling: !!this.$forge
          .getExtension("Autodesk.AEC.Hypermodeling")
          .findLoadedSheetFromLevelAndSheetIndex(floor, index),
        ...e,
      }));
    },

    getAvailableFloor() {
      return this.$forge
        .getExtension("Autodesk.AEC.Hypermodeling")
        .floorSelector.floorData.map((e, index) => ({
          ...e,
          children: this.getAvailableSheet(index),
        }))
        .filter((e) => e.children.length > 0);
    },

    getHierarchy() {
      const floors = this.getAvailableFloor();

      return floors;
    },

    updateActiveState() {
      console.log("updateActiveState");
      if (this.$forge.sheetPanels) {
        const sheets = this.hierarchy.reduce(
          (accumulator, currentValue) => [
            ...currentValue.children,
            ...accumulator,
          ],
          []
        );

        this.activedItems = sheets.filter((s) =>
          this.$forge.sheetPanels[s.guid].isVisible()
        );

        Object.keys(this.$forge.sheetPanels).forEach((id) => {
          this.$forge.sheetPanels[id].closer.onclick = () => {
            let index = this.activedItems.findIndex((e) => e.guid === id);
            this.activedItems.splice(index, 1);
          };
        });
      }
    },

    sheetsActive(sheets) {
      console.log("open in panel: ", sheets);
      if (!this.ready || !sheets) return;

      if (!this.$forge.sheetPanels) this.$forge.sheetPanels = {};

      // All exist panel
      const panelIds = Object.keys(this.$forge.sheetPanels);
      const activedIds = sheets.map((e) => e.guid);

      // exist and activated
      sheets
        .filter((e) => panelIds.includes(e.guid))
        .forEach((e) => {
          this.$forge.sheetPanels[e.guid].setVisible(true);
        });

      // not exist but activated
      sheets
        .filter((e) => !panelIds.includes(e.guid))
        .forEach((e) => {
          let panel = this.createPanel(e);
          this.$forge.sheetPanels[e.guid] = panel;
        });

      // exist but not activated
      panelIds
        .filter((id) => !activedIds.includes(id))
        .forEach((id) => {
          this.$forge.sheetPanels[id].setVisible(false);
        });
    },
    createPanel(sheet) {
      const DrawingPanel = require("@/components/DrawingPanel").default;

      let panel = new DrawingPanel(
        window.viewerService["webimsync-forge-viewer"].View,
        this.$forge.container,
        `drawing-panel-${sheet.guid}`,
        sheet.name,
        sheet.node
      );
      panel.closer.onclick = () => {
        let index = this.activedItems.findIndex((e) => e.guid === sheet.guid);
        this.activedItems.splice(index, 1);
      };
      panel.setVisible(true);

      return panel;
    },
    addToForge(node) {
      console.log("add to forge: ", node);
      this.$forge
        .getExtension("Autodesk.AEC.Hypermodeling")
        .loadSheetFromLevel(node.level, node.index);
    },
    removeFromForge(node) {
      console.log("remove from forge: ", node);
      const sheetId = this.$forge
        .getExtension("Autodesk.AEC.Hypermodeling")
        .findLoadedSheetFromLevelAndSheetIndex(node.level, node.index);

      this.$forge
        .getExtension("Autodesk.AEC.Hypermodeling")
        .unloadSheet(sheetId);
    },
    toggleCloseEvent() {
      this.$emit("close");
    },
    test(e) {
      console.log(e);
    },
  },
};
</script>
