<i18n>
{
  "zh-Hant": {
    "Whoops_404": "糟糕, 404",
    "the_page_you_were_looking_for_does_not_exist": "你要找的頁面不存在!",
    "get_me_out_of_here": "讓我離開這裡"
  },
  "en": {
    "Whoops_404": "whoops, 404",
    "the_page_you_were_looking_for_does_not_exist": "this page doesn't exist",
    "get_me_out_of_here": "let me out of here"
  }
}
</i18n>

<template>
  <v-app>
    <v-container fill-height style="height: calc(100vh - 58px)">
      <v-layout align-center justify-center>
        <v-flex text-center sm10 md6>
          <v-img src="@/assets/error404.png"></v-img>
          <h1 class="titile-error404 primary--text mb-3">
            {{ $t("Whoops_404") }}
          </h1>
          <p>
            {{ $t("the_page_you_were_looking_for_does_not_exist") }}
          </p>
          <v-btn :href="portal">{{ $t("get_me_out_of_here") }}!</v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "FourOhFour",
  data: () => ({
    portal: process.env.VUE_APP_PORTAL_URL,
  })
};
</script>

<style lang="scss" scoped>
.titile-error404 {
  font-size: 48px;
}
</style>
