<template>
  <v-card width="100%" height="100%" :loading="!ready" tile flat>
    <Header :hide-search="true" @close="toggleCloseEvent" />
    <v-treeview
      dense
      hoverable
      open-on-click
      transition
      activatable
      :items="viewpoints"
      item-key="bimModelItemId"
      item-text="name"
      return-object
      @update:active="applyCamera"
    >
    </v-treeview>
  </v-card>
</template>

<script>
import Header from "@/components/NavHeader";

export default {
  name: "ViewPoints",
  components: { Header },
  props: {
    urns: {
      type: Array,
    },
  },
  data: () => ({
    viewpoints: [],
    ready: false,
  }),
  mounted() {
    if (
      this.$forge &&
      this.$forge.model &&
      this.$forge.model.getData().instanceTree
    ) {
      this.ready = true;
      this.viewpoints = this.getViewPointInfo();
    } else {
      this.$emit("initial:failed");
    }
  },
  methods: {
    getCameras() {
      const data = this.$forge.getAllModels().map((e) => e.getData());
      return data.map((e) => ({ urn: e.urn, cameras: e.cameras }));
    },
    getViewPointInfo() {
      const cameras = this.getCameras();
      return this.urns.map((model) => {
        const data = model.document
          .getRoot()
          .search({ type: "geometry" })[0].data;
        const camera = cameras.find((e) => e.urn === model.urn).cameras;
        const cameraInfo = model.document
          .getRoot()
          .search({ role: "3d", type: "view" })
          .map((e, idx) => ({
            ...e.data,
            camera: camera[idx],
            bimModelItemId: `${model.bimModelItemId}/${e.guid}`,
          }))
          .filter((e) => e.camera);

        return {
          ...data,
          bimModelItemId: `${model.bimModelItemId}`,
          children: cameraInfo,
        };
      });
    },
    applyCamera(e) {
      console.log(e);
      const camera = e.length > 0 ? e[0].camera : null;
      if (camera) {
        this.$forge.impl.setViewFromCamera(camera);
      }
    },
    toggleCloseEvent() {
      this.$emit("close");
    },
  },
};
</script>
